const kaomojis = [
  "o(≧∇≦o)",
  "(/^▽^)/",
  "(✿ ♥‿♥)",
  "♥(ˆ⌣ˆԅ)",
  "(*´ڡ`●)",
  "(っ- ‸ – ς)",
  "꒰๑•̥﹏•̥๑꒱",
  "(=ↀωↀ=)✧",
  "(ↀДↀ)⁼³₌₃",
  "(*˘︶˘*).｡.:*♡",
  "(≧◡≦) ♡",
  "σ(≧ε≦σ) ♡",
  "☆⌒ヽ(*'､^*)",
  "(⁄ ⁄>⁄ ▽ ⁄<⁄ ⁄)",
  "(ノ_<。)ヾ(´▽｀)",
  "＼(º □ º l|l)/",
  "┐(￣∀￣)┌",
  "(＠_＠)",
  "∑(O_O;)",
  "(⌒ω⌒)ﾉ",
  "(^_<)〜☆",
  "m(_ _;m)",
  "(＾་།＾)",
  "__φ(．．)",
  "C= C= C= C=┌(｀ー´)┘",
  "(￣ρ￣)..",
  "( =ω=)..",
  "ʕ •ᴥ• ʔ",
  "∪･ω･∪",
  "／(≧ x ≦)＼",
  "｡ﾟ(ﾟ´(00)`ﾟ)ﾟ｡",
  "(◉Θ◉)",
  "(°)#))<<",
  "٩(๑･ิᴗ･ิ)۶٩(･ิᴗ･ิ๑)۶",
  "ヽ(≧◡≦)八(o^ ^o)ノ",
  "(｀⌒*)O-(｀⌒´Q)",
  "(ﾒ￣▽￣)︻┳═一",
  "(⊃｡•́‿•̀｡)⊃━✿✿✿✿✿✿",
  "(ﾉ≧∀≦)ﾉ ‥…━━━",
  "ԅ(¯﹃¯ԅ)",
  "((ヽ(๑╹◡╹๑)ﾉ))",
  "─=≡Σ((( つ•̀ω•́)つ",
  "（￢з￢）σ",
  "(o￣∇￣)=◯)`ν゜)",
  "(*´0)ゞ",
  "(´-ω-)´_ _)´-ω-)´_ _)zZZ",
  "(●っゝω・)っ～☆",
  "♪(‘εﾟ人)*.+゜",
  "━━＼(´∀｀●)／━━",
  "(´＿｀。)",
  "╰(*´︶`*)╯(´・ω・｀)",
  "▼・ᴥ・▼",
  "=^._.^= ∫",
  " /ᐠ｡ꞈ｡ᐟ✿ ",
  "(┛✧Д✧))┛彡┻━┻",
  "┣ﾍ(^▽^ﾍ)Ξ(ﾟ▽ﾟ*)ﾉ┳━┳ ~",
  "p(●｀□´●)q",
  "(///∇///✿)",
  "(✿◕㉨◕)",
  "〆(・∀・＊)",
  "( ・∀・)っ■",
  "༼;´༎ຶ ۝ ༎ຶ༽",
  "(´◑ω◐`)",
  "||Φ|(|ﾟ|∀|ﾟ|)|Φ||"
];

export const randomKaomoji = () => kaomojis[Math.floor(Math.random() * kaomojis.length)];